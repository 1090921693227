//generate a custom Image component that show an image from a base64 from the props and the image must be a 100x150px and mantaing the aspect ratio and have an icon for delete in the top right corner and when is pressed it call a callback

import React from 'react'
import styled from '@emotion/styled'
import { Box, Icons } from 'stylewhere/components'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import 'react-photo-view/dist/react-photo-view.css'

interface Props {
  image: string
  onDelete: () => void
}

export default function CustomImage({ image, onDelete }: Props) {
  return (
    <Container>
      <PhotoView src={image} key={image}>
        <Image src={image} />
      </PhotoView>
      <DeleteButton onClick={onDelete}>
        <Icons.Close />
      </DeleteButton>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100px;
  height: 150px;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
`

const DeleteButton = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 5px;
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
`
